import React from 'react';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('big-picture');

const BigPicture = ({
    noBackground = false,
    right = false,
    bottom = false,
    width = '100%',
    height = '100%',
    background,
    backgroundSm,
    heightSm,
    children,
    alt = '',
}) => {
    const defaultPath = background.images[background.images.length - 1].path;
    const extension = defaultPath.split('.').pop();
    const backgroundMobile = backgroundSm.images[0].path;
    const webpUrl = `${backgroundMobile}.webp`;

    return (
        <div className={b.mix('card')}>
            <div>
                <picture>
                    <source type="image/webp" srcSet={webpUrl} height={heightSm} media="(max-width: 500px)" />
                    <source type="image/jpeg" srcSet={backgroundMobile} height={heightSm} media="(max-width: 500px)" />
                    {background.images.map((img, j) => {
                        const media =
                            j > 0 && j == background.images.length - 1
                                ? `(min-width: ${background.images[j == 0 ? j : j - 1].width}px)`
                                : `(max-width: ${img.width}px)`;

                        return (
                            <React.Fragment key={j}>
                                <source type="image/webp" srcSet={img.path + '.webp'} media={media} />
                                <source type={`image/${extension == 'jpg' ? 'jpeg' : extension}`} srcSet={img.path} media={media} />
                            </React.Fragment>
                        );
                    })}
                    <img className="card-img" loading="eager" width={width} height={height} src={defaultPath} alt={alt} />
                </picture>
            </div>
            <div className={`card-img-overlay ${right ? 'right' : 'left'} ${bottom ? 'bottom' : ''}${noBackground ? '' : 'bg-white'} m-md-5 p-md-5`}>
                {children}
            </div>
        </div>
    );
};

export default BigPicture;
