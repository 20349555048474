import React, { useCallback, useEffect, useState } from 'react';
import bemCn from 'bem-cn';

import CookieService from '../../services/cookie.service';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';

import { smartBannerShow } from '../../utils';
import { RouteFormatter } from '../../routes';

import SniffButton from '../../components/sniff-button';

import './top-sticky.scss';

const b = bemCn('home');

const HomeTopSticky = ({ showAppBanner, useStateSSR }) => {
    const isMobile = useMobileScreen();
    const [showNav, setShowNav] = useState(false);
    const [isShowAppBanner, setIsShowAppBanner] = useState(false);

    let y = typeof window !== 'undefined' ? window.scrollY : 0;

    const scrollTop = useCallback((e) => {
        const window = e.currentTarget;
        const hero = document.getElementById('hero');
        const offset = hero.offsetHeight - hero.offsetTop;

        if (y < offset || window.scrollY < offset) {
            setShowNav(false);
        } else if (window.scrollY > offset - 1) {
            setShowNav(true);
        }
        y = window.scrollY;
    });

    useEffect(() => {
        y = window.scrollY;
        window.addEventListener('scroll', scrollTop);
        return () => {
            window.removeEventListener('scroll', scrollTop);
        };
    }, [scrollTop]);

    useEffect(() => {
        if (!isMobile) {
            return;
        }

        if (typeof showAppBanner === 'boolean') {
            setIsShowAppBanner(showAppBanner);
            return;
        }

        const hasCookie = CookieService.get(COOKIE_PARAM_NAME.SNIFF_APP_DOWNLOAD_BANNER);
        const canShowSmartBanner = smartBannerShow();

        if (hasCookie || !canShowSmartBanner || !useStateSSR) {
            return;
        } else {
            setIsShowAppBanner(true);
        }

        const checkCookie = setInterval(() => {
            const hasCookie = CookieService.get(COOKIE_PARAM_NAME.SNIFF_APP_DOWNLOAD_BANNER);
            setIsShowAppBanner(!hasCookie);
        }, 300);

        return () => {
            clearInterval(checkCookie);
        };
    }, [showAppBanner]);

    return (
        <div
            className={b('sticky')
                .mix(showNav ? 'show' : '')
                .mix(isShowAppBanner ? 'has-app-banner' : '')}
        >
            <div className="desktop-container px-md-0">
                <p className="w-100 snif-s1 snif-m1-pc snif-semibold d-none d-md-block">Rent safe and private dog parks hosted by locals</p>
                <a className="w-100 w-md-auto" href={RouteFormatter.listings({})}>
                    <SniffButton className="d-md-none w-100" size="sm">
                        Explore spots near me
                    </SniffButton>
                    <SniffButton className="d-none d-md-block" size="lg">
                        Explore spots near me
                    </SniffButton>
                </a>
            </div>
        </div>
    );
};

export default HomeTopSticky;
