import { PLATFORM } from './consts/platform.consts';
import CookieService from './services/cookie.service';
import { COOKIE_PARAM_NAME } from './consts/cookies.consts';

export const loadUserFromStorage = () => {
    try {
        let user = CookieService.get(COOKIE_PARAM_NAME.USER);
        if (user === null) {
            return {};
        }
        return JSON.parse(user);
    } catch (err) {
        return undefined;
    }
};

export function getIdFromSlug(slug) {
    return slug.split('-').pop();
}

export function validateEmail(email) {
    const re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function getBannerStatus() {
    let isSmartBannerShow = true;
    if (CookieService.get(COOKIE_PARAM_NAME.SMARTBANNER_INSTALLED)) {
        isSmartBannerShow = false;
    } else {
        if (CookieService.get(COOKIE_PARAM_NAME.SMARTBANNER_CLOSED)) {
            isSmartBannerShow = false;
        }
    }
    return isSmartBannerShow;
}

export function checkPlatform() {
    if (navigator.userAgent.match(/iPhone/) || navigator.userAgent.match(/iPad/)) {
        return PLATFORM.IOS;
    }

    if (navigator.userAgent.match(/Android/)) {
        return PLATFORM.ANDROID;
    }

    return PLATFORM.DESKTOP;
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function resizeTextarea(e) {
    e.style.height = e.scrollHeight + 'px';
}

export function smartBannerShow() {
    if (typeof window === 'undefined') {
        return false;
    }

    const platform = checkPlatform();
    const hasCookie = CookieService.get(COOKIE_PARAM_NAME.SNIFF_APP_DOWNLOAD_BANNER);
    const myUrl = typeof window === 'undefined' ? '' : window.location.pathname;
    const ua = typeof window === 'undefined' ? '' : window.navigator.userAgent;
    const isReserve = myUrl.startsWith('/reserve/');
    const isSafari = ua.match(/safari/i) && !ua.match(/chrome/i) && !ua.match(/chromium/i);

    if ((platform === PLATFORM.ANDROID || (platform === PLATFORM.IOS && !isSafari)) && !hasCookie && !isReserve) {
        return true;
    }

    return false;
}
