import '../../../../../styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { AWS_STATIC_ASSET_HOST } from '../../../../consts/env.consts';
import SniffToast, { toasterDanger } from '../../utils/sniff-toast';
import { setFirstClick } from '../../../../helpers/firstClick';
import useMutateTrackEvent from '../../../../services/hooks/useMutateTrackEvent';
import useMutateViewHomeV3 from '../../../../services/hooks/useMutateViewHomeV3';
import useMobileScreen from '../../hooks/useMobileScreen';
import HomeSSR from '../../../../pages/home/home-ssr';
import '../../../../helpers/firebase';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${AWS_STATIC_ASSET_HOST}/packs/`;

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
});

const Home = (props) => {
    const isMobileSSR = useMobileScreen();

    useEffect(() => {
        setFirstClick();
    }, []);

    return (
        <ApolloProvider client={client}>
            <SniffToast />
            <HomeSSR
                {...{
                    ...props,
                    isMobileSSR,
                    useStateSSR: useState,
                    useEffectSSR: useEffect,
                    useRefSSR: useRef,
                    useMutateViewHomeV3,
                    useMutateTrackEvent,
                    toasterDanger,
                }}
            />
        </ApolloProvider>
    );
};

export default Home;
