import { isEqual } from 'apollo-utilities';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { SF_AVAILABILITY_OPTIONS } from '../../consts/search-filter.const';
import { SPOT_FENCE_HEIGHT, SPOT_PRESENCE_STATE, SPOT_TAGS } from '../../consts/spot.consts';
import CookieService from '../../services/cookie.service';

export function convertTo12Hours(time) {
    let integer = Math.floor(time);
    let fractional = time - integer === 0 ? '' : ':30';
    if (time === 0) {
        return '12 AM';
    }
    if (time === 12) {
        return '12 PM';
    }
    if (time < 12) {
        return `${integer}${fractional} AM`;
    }
    if (time > 12) {
        return `${integer - 12}${fractional} PM`;
    }
}

export function getAllTags(filters) {
    let mappedTags = Object.entries(SPOT_TAGS).map((q) => ({
        name: q[1],
        active: false,
    }));
    if (filters.tags && filters.tags) {
        for (let tag of filters.tags) {
            mappedTags.map((q) => (q.name === tag.name ? (q.active = true) : null));
        }
    }
    return mappedTags;
}

export function getInitialPresents(filters) {
    const questions = Object.entries(SPOT_PRESENCE_STATE).map((q) => ({
        value: q[0],
        title: q[1],
        active: false,
    }));
    let presents = {
        dogsPresent: JSON.parse(JSON.stringify(questions)),
        peoplePresent: JSON.parse(JSON.stringify(questions)),
        domesticAnimalsPresent: JSON.parse(JSON.stringify(questions)),
        privateEntry: JSON.parse(JSON.stringify(questions)),
    };
    if (filters) {
        for (let key in presents) {
            if (filters[key]) {
                presents[key].map((q) => (q.value === filters[key] ? (q.active = true) : null));
            }
        }
    }
    return presents;
}

export const loadFiltersFromStorage = () => {
    try {
        const searchFilters = JSON.parse(CookieService.get(COOKIE_PARAM_NAME.SEARCH_FILTERS));
        const availabilityFilters = JSON.parse(CookieService.get(COOKIE_PARAM_NAME.SEARCH_FILTERS_AVAILABILITY));
        const addressFilters = JSON.parse(CookieService.get(COOKIE_PARAM_NAME.SEARCH_FILTERS_LOCATION));
        if (searchFilters === null && availabilityFilters === null) {
            return {};
        } else {
            const filters = {
                filters: { ...searchFilters?.filters, ...availabilityFilters, ...addressFilters },
                count: searchFilters?.count,
            };
            return filters;
        }
    } catch (err) {
        return undefined;
    }
};

export function applyFilters(spots, filters) {
    return spots.filter((spot) => filterCondition(spot, filters));
}

function filterCondition(spot, filters) {
    fenceHeightCondition(spot.fenceHeight, filters.fenceHeight);
    return (
        (filters.price ? spot.price <= filters.price && spot.price : true) &&
        sizeCondition(spot, filters) &&
        presentCondition(spot.dogsPresent, filters.dogsPresent) &&
        presentCondition(spot.peoplePresent, filters.peoplePresent) &&
        presentCondition(spot.domesticAnimalsPresent, filters.domesticAnimalsPresent) &&
        privateEntryCondition(spot.privateEntry, filters.privateEntry) &&
        (filters.instantBook ? spot.instantBook === filters.instantBook : true) &&
        (filters.enclosureType ? spot.enclosureType === filters.enclosureType : true) &&
        fenceHeightCondition(spot.fenceHeight, filters.fenceHeight) &&
        tagsCondition(filters.tags, spot.tags)
    );
}

function fenceHeightCondition(spotFence, filterFence) {
    if (filterFence) {
        if (spotFence) {
            const heightArr = Object.keys(SPOT_FENCE_HEIGHT);
            const indexFilterFence = heightArr.findIndex((h) => h === filterFence);
            const indexSpotFence = heightArr.findIndex((h) => h === spotFence);
            if (indexSpotFence >= indexFilterFence) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
}

function sizeCondition(spot, filters) {
    let res = true;
    if (filters.size) {
        let { min, max } = filters.size;
        min = min * 1;
        max = max * 1;
        if (!isNaN(min) || !isNaN(max)) {
            res = !!spot.size;
            if (res) {
                if (!isNaN(min)) {
                    res = res && convertToAcres(spot.size, spot.sizeUnits) >= min;
                }
                if (!isNaN(max)) {
                    res = res && convertToAcres(spot.size, spot.sizeUnits) <= max;
                }
            }
        }
    }
    return res;
}

const acresOneSqFT = 0.0000229568;
function convertToAcres(value, measure) {
    if (measure === 'SQ_FT') {
        return value * acresOneSqFT;
    } else {
        return value;
    }
}

function privateEntryCondition(present, filter) {
    if (filter) {
        if (present === 'YES') {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

function presentCondition(present, filter) {
    return filter ? true : present === 'NO';
}

function tagsCondition(filtersTags, tags) {
    if (filtersTags.length !== 0) {
        let fTags = filtersTags.map((value) => value.name);
        if (tags) {
            return !!tags.filter((tag) => fTags.includes(tag)).length;
        } else {
            return false;
        }
    }
    return true;
}

const memoize = (fn) => {
    let cache = {};
    return (...args) => {
        let n = args[0];
        if (isEqual(n, cache['filters'])) {
            return cache['result'];
        } else {
            let result = fn(...args);
            cache['filters'] = n;
            cache['result'] = result;
            return result;
        }
    };
};

export function calcCountFilters(filters) {
    if (!filters) {
        return 0;
    }

    let count = 0;
    filters.availability && filters.availability.startDate && count++;
    filters.enclosureType && count++;
    filters.fenceHeight && count++;
    filters.privateEntry && count++;
    filters.price && filters.price !== '' && count++;
    filters.size && filters.size.min && !isNaN(+filters.size.min) && count++;
    filters.size && filters.size.max && !isNaN(+filters.size.max) && count++;
    filters.reviewRating && count++;
    filters.advanceNotice && count++;
    filters.tags && filters.tags.length !== 0 && count++;
    !filters.dogsPresent && count++;
    !filters.peoplePresent && count++;
    !filters.domesticAnimalsPresent && count++;

    return count;
}

export function calcCountSearchFilters(filters) {
    if (!filters) {
        return 0;
    }

    let count = 0;
    filters.enclosureType && count++;
    filters.minimumSize && count++;
    filters.reviewRating && count++;
    filters.advanceNotice && count++;
    filters.distance && count++;
    filters.availability && filters.availability.value !== SF_AVAILABILITY_OPTIONS[0].value && count++;
    filters.dogsPresent && count++;
    filters.peoplePresent && count++;
    filters.domesticAnimalsPresent && count++;
    filters.topSpots && count++;
    filters.newSpots && count++;
    filters.cleanerSpots && count++;
    filters.sniffpassDiscount && count++;
    filters.cheaperSpots && count++;
    filters.availableDates && count++;
    filters.availableTimes && count++;
    filters.maximumDogsAllowed && count++;
    filters.minimumPrice && count++;
    filters.maximumPrice && count++;
    filters.sortBy && count++;

    if (filters.amenities?.includes('AgilityEquipment')) {
        count += filters.amenities.length - 1;
    } else {
        count += filters.amenities?.length || 0;
    }

    return count;
}

export const getCountFilters = memoize(calcCountFilters);
export const getCountSearchFilters = calcCountSearchFilters;

export const range = (start, end, step = 0) => {
    const list = [];
    let value = start;
    list.push(value);
    while (value < end) {
        value += step;
        list.push(value);
    }
    return list;
};

export const toFloat = (n) => {
    if (n.length === 0) {
        return 0;
    }
    return parseFloat(n);
};
